<template>
	<v-menu :open-on-hover="false" :close-on-click="true" :close-on-content-click="true" :offset-x="false" :offset-y="true">
		<template v-slot:activator="{ on }">
			<v-btn icon class="mx-4" v-on="on">
				<v-badge
					:content="!!unreadNotifications ? unreadNotifications.length : 0"
					:value="!!unreadNotifications ? unreadNotifications.length : 0"
					overlap
				>
					<v-icon>mdi-bell</v-icon>
				</v-badge>
			</v-btn>
		</template>
		<v-card rounded="xl" elevation="20" max-width="400">
			<v-card-title>
				<span class="mx-auto">
					{{ $t('notifications.notificationsCenter') }}
				</span>
			</v-card-title>
			<v-divider />
			<v-list color="transparent" dense nav class="pa-0">
				<template v-if="!setupInfo.company.ready">
					<v-list-item class="px-4">
						<v-list-item-content>
							<v-list-item-title class="text-wrap">
								{{ $t('notifications.almostThere') }}
							</v-list-item-title>
							<v-progress-linear v-model="setupInfo.company.progress" color="green" height="15">
								<strong>{{ setupInfo.company.progress }}%</strong>
							</v-progress-linear>
						</v-list-item-content>
					</v-list-item>
					<v-list-item class="px-4" :to="{ name: setupInfo.company.nextStep.route }">
						<v-list-item-icon>
							<v-icon>mdi-fast-forward</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-subtitle>
								{{ $t('notifications.justNow') }}
							</v-list-item-subtitle>
							<v-list-item-title class="text-wrap">
								{{ $t('notifications.nextStep') }}: {{ setupInfo.company.nextStep.description }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
				<template v-for="notification in unreadNotifications">
					<v-list-item class="px-4" :key="notification.id" @click="followNotification(notification)">
						<v-list-item-icon>
							<v-icon>mdi-email</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-subtitle>
								<time :datetime="notification.timestamp">{{ humanTime(notification) }}</time>
							</v-list-item-subtitle>
							<v-list-item-title class="text-wrap">{{ notification.content }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-list>
			<v-list-item dense class="py-1 px-4 d-flex align-center justify-center flex-wrap" color="background" :to="{ name: 'NotificationsCenter' }">
				<span class="text-subtitle-1 warning--text">{{ $t('notifications.showAllNotifications') }}</span>
			</v-list-item>
		</v-card>
	</v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'NotificationsWidget',
	data() {
		return {
			notificationsOpened: false
		}
	},
	computed: {
		...mapGetters({
			setupInfo: 'user/setupInfo',
			unreadNotifications: 'notifications/unreadNotifications'
		})
	},
	methods: {
		humanTime(notification) {
			return this.$moment(notification.timestamp).fromNow()
		},
		followNotification(notification) {
			if (notification.type === 'message' && notification.chatID) {
				this.readNotification(notification).then(() => {
					this.$router.push({ name: 'MessagesChat', params: { id: notification.chatID } })
				})
			} else if (notification.type === 'offer_change_approved' && notification.offerID) {
				this.readNotification(notification).then(() => {
					this.$router.push({ name: 'Offer', params: { id: notification.offerID } })
				})
			} else if (notification.type === 'offer_change_refused' && notification.offerID) {
				this.readNotification(notification).then(() => {
					this.$router.push({ name: 'Offer', params: { id: notification.offerID } })
				})
			} else {
				this.readNotification(notification).then(() => {
					this.$router.push({ name: 'NotificationsCenter' })
				})
			}
		},
		...mapActions('notifications', ['readNotification'])
	}
}
</script>

<style scoped>
.v-menu__content {
	border-radius: 24px !important;
}
</style>
